(function() {
  'use strict';

  /* jshint undef: true */
  /* global _:false, moment:false */
  angular
    .module('linshare.contactsLists')
    .constant('moment', moment)
    .constant('_', _);
})();
