angular.module(
  'linshare.document',
  [
    'restangular',
    'ngTable',
    'linshare.components',
    'linshare.utils',
    'pascalprecht.translate',
  ]
);

require('./constants');
require('./run');
require('./controllers/documentController');
require('./controllers/documentsController');
require('./directives/checkDropdownPosition/check-dropdown-position');
require('./directives/eventPropagationStopDirective');
require('./directives/lsItemSelectionDirective');
require('./directives/lsDocumentsTable/lsDocumentsMenuDirective');
require('./directives/lsDocumentsTable/lsDocumentsTableDirective');
require('./services/documentRestService');
require('./services/documentRestServiceRun');
