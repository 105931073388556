// Deps
require('angular/angular');
require('@flowjs/flow.js/dist/flow');
require('@flowjs/ng-flow/dist/ng-flow');
require('angular-animate/angular-animate');
require('angular-aria/angular-aria');
require('angular-cookies/angular-cookies');
require('angular-http-auth/dist/http-auth-interceptor');
require('angular-local-storage/dist/angular-local-storage');
require('angular-material/angular-material');
require('angular-messages/angular-messages');
require('angular-promise-extras/angular-promise-extras');
require('angular-resource/angular-resource');
require('angular-route/angular-route');
require('angular-sanitize/angular-sanitize');
require('angular-touch/angular-touch');
require('angular-translate/dist/angular-translate');
require('angular-translate-interpolation-messageformat/angular-translate-interpolation-messageformat');
require('angular-translate-loader-partial/angular-translate-loader-partial');
require('angular-ui-bootstrap/dist/ui-bootstrap-tpls');
require('@uirouter/angularjs/release/angular-ui-router');
require('jquery/dist/jquery');
require('bootstrap/dist/js/bootstrap');
require('js-base64/base64');
require('lodash/lodash');
require('moment/moment');
require('re-tree/re-tree');
require('ua-device-detector/ua-device-detector');
require('ng-device-detector/ng-device-detector');
require('ng-table/bundles/ng-table');
require('../node_modules/clipboard/dist/clipboard');
require('ngclipboard/dist/ngclipboard');
require('restangular/dist/restangular');
require('jquery.qrcode/jquery.qrcode.min.js');
require('sweetalert/dist/sweetalert.min.js');
require('uuid');

// Moment locales
require('moment/locale/fr');
require('moment/locale/vi');
require('moment/locale/ru');

// Internal modules
require('../app/scripts/app');
require('../app/modules/linshare.anonymousUrl/app');
require('../app/modules/linshare.audit/linshareAuditModule');
require('../app/modules/linshare.authentication/authentication');
require('../app/modules/linshare.contactsLists/contactsListsModule');
require('../app/modules/linshare.directives/directivesModule');
require('../app/modules/linshare.document/document');
require('../app/modules/linshare.components/appComponents');
require('../app/modules/linshare.external/externalModule');
require('../app/modules/linshare.guests/linshareGuestsModule');
require('../app/modules/linshare.quota/linshareQuotaModule');
require('../app/modules/linshare.receivedShare/receivedShare');
require('../app/modules/linshare.resetPassword/resetPasswordModule');
require('../app/modules/linshare.share/share');
require('../app/modules/linshare.sharedSpace/sharedSpace');
require('../app/modules/linshare.upload/linshareUploadModule');
require('../app/modules/linshare.user/userProfile');
require('../app/modules/linshare.utils/linshareUtilsModule');
require('../app/modules/linshare.safeDetails/linshareSafeDetailsModule');
require('../app/modules/linshare.changePassword/changePasswordModule');
require('../app/modules/linshare.secondFactorAuthentication/secondFactorAuthentication');
require('../app/modules/linshare.uploadRequests/uploadRequests');